import { Outlet, Link } from "react-router-dom";
import React from 'react'

const Footer = () => {
  return (
    <>
    
    <footer>

    <div className="container">
        <div className="row">
            <div className="col-lg-3 ">
                <div>
                    <h3 className="h3_heading">About us Company</h3>
                    <p className='footer_text'>Adhiraj Infotech brings across the experienced team of web designers and offers comprehensive websites. We offer low cost programming and design services for every aspect of website development Company in india.</p>
                </div>
            </div>
            <div className="col-lg-3">
                <h3 className="h3_heading">Useful Links</h3>
                <ul className="navlist">
                    <li className='nav'><Link to='/'><i className="bi bi-arrow-left"></i> Home</Link></li>
                    <li className='nav'><Link to='/aboutus'><i className="bi bi-arrow-left"></i> About Us</Link></li>
                    <li className='nav'><Link to='/portfolio'><i className="bi bi-arrow-left"></i> Portfolio</Link></li>
                    <li className='nav'><Link to='/blogs'><i className="bi bi-arrow-left"></i> Blogs</Link></li>
                    <li className='nav'><Link to='/reviews'><i className="bi bi-arrow-left"></i> Reviews</Link></li>
                    <li className='nav'><Link to='/career'><i className="bi bi-arrow-left"></i> Career</Link></li>
                    <li className='nav'><Link to='/contact'><i className="bi bi-arrow-left"></i> Contact us</Link></li>

                   







                </ul>
            </div>
            <div className="col-lg-3">
                <h3 className="h3_heading">Our Services</h3>
                <ul className="navlist">
                    <li className='nav'><Link to='/webdesign'> <i className="bi bi-arrow-left"></i> Web Design & Development</Link></li>
                    <li className='nav'><Link to='/mobileapp'><i className="bi bi-arrow-left"></i> Mobile App Development</Link></li>
                    <li className='nav'><Link to='/ecommercedevelopment'><i className="bi bi-arrow-left"></i> E-Commerce Development</Link></li>
                    <li className='nav'><Link to='/erpdevelopment'><i className="bi bi-arrow-left"></i> CRM/ERP Development</Link></li>
                    <li className='nav'><Link to='/seoservices'><i className="bi bi-arrow-left"></i> Seo Services</Link></li>
                    <li className='nav'><Link to='/digitalmarketingservices'><i className="bi bi-arrow-left"></i> Digital Marketing Services</Link></li>
                    <li className='nav'><Link to='/logodesign'><i className="bi bi-arrow-left"></i> Logo Design</Link></li>
                    <li className='nav'><Link to='/graphicsdesign'><i className="bi bi-arrow-left"></i> Graphics Design</Link></li>
                    <li className='nav'><Link to='/bulksms'><i className="bi bi-arrow-left"></i> Bulk SMS</Link></li>
                </ul>
            </div>
            <div className="col-lg-3">
                <h3 className="h3_heading">Contact us</h3>
                <ul className="navlist">
                    <li className='address'><i className="bi bi-geo-alt"></i> 318, Maharaja Kameshwar Complex, Fraser Road Patna, Bihar 800001</li>
                    <li className='nav'><a href='#'><i className="bi bi-telephone"></i> +91 93866 39817</a></li>
                    <li className='nav'><a href='#'><i className="bi bi-envelope"></i> info@adhirajinfotech.com</a></li>
                </ul>
            </div>
        </div>
    </div>
    <a
  id="scrollUp"
  href="#top"
  title="Scroll to top"
  style={{ position: "fixed", zIndex: 2147483647 }}
/>

        
    </footer>
    <div className="container">
            <div className="row">
                <div className="col-lg-12">
                    <div className="copyright">
                        <p className='text-center p-2'>© 2024. Adhiraj Infotech. All Rights Reserved.</p>
                    </div>
                </div>
            </div>
        </div>
        
    </>
  )
}

export default Footer
