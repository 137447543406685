import { useEffect } from 'react';
import React from 'react'

const Ecommercedevelopment = () => {

  useEffect(()=> {
    document.title="E-Commerce Website Development Company in Patna";
    }, []);


  return (
    <>
   <section id="innerPG-banner">
  <div className="container-fluid">
    <div className="row">
      <div
        className="banner-item col-lg-12"
        style={{ backgroundImage: 'url("/assets/images/about-us-banner.jpg")' }}
      >
        <div className="innerBanner-caption">
          <h1>E-Commerce Development</h1>
          <p>
          We’re a team of creatives who are excited about new ideas
          </p>
        </div>
      </div>
    </div>
  </div>
</section>

    <section>
      <div className="container">
        <div className="row">
          <div className="col-lg-6"></div>
          <div className="col-lg-6"></div>
        </div>
      </div>
    </section>
    </>
  )
}

export default Ecommercedevelopment
