import { useEffect } from 'react';
import React from 'react'
import { Link } from 'react-router-dom';

const Contact = () => {
  useEffect(()=> {
    document.title="Contact us | Web Design Company in Patna";
    }, []);
  
  return (
    <>
    <section id="innerPG-banner">
  <div className="container-fluid">
    <div className="row">
      <div
        className="banner-item col-lg-12"
        style={{ backgroundImage: 'url("/assets/images/about-us-banner.jpg")' }}
      >
        <div className="innerBanner-caption">
          <h1>Contact us</h1>
          <p>
          We’re a team of creatives who are excited about new ideas
          </p>
        </div>
      </div>
    </div>
  </div>
</section>


<section className='contactus'>
  <div className="container-fluid">
  <div className="row">
    <div className="col-lg-12">
      <div className="map">
      <iframe
  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14391.729715546495!2d85.1380317!3d25.6071615!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39ed59919b912a47%3A0x890ea0778824eb8b!2sAdhiraj%20Infotech%20Pvt.Ltd.!5e0!3m2!1sen!2sin!4v1723197318779!5m2!1sen!2sin"
  width="100%"
  height={450}
  style={{ border: 0 }}
  allowFullScreen=""
  loading="lazy"
  referrerPolicy="no-referrer-when-downgrade"
/>

</div>
      </div>
    </div>
  </div>
</section>


    <section>
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="enquiryform">
              <h5 className='headeingred'>CONTACT ENQUIRY FORM</h5>
              <h3 className='headeing'>Have Question? Write a Message</h3>
              <p>We will catch you as early as we receive the message

</p>
              <form className="row g-3">
  <div className="col-md-6">
    <label htmlFor="inputEmail4" className="form-label">
      Enter name
    </label>
    <input type="text" name="name" className="form-control" required />
  </div>
  <div className="col-md-6">
    <label htmlFor="inputEmail4" className="form-label">
      Enter email
    </label>
    <input
      type="email"
      name="email"
      className="form-control"
      id="inputEmail4"
      required
    />
  </div>
  <div className="col-md-6">
    <label htmlFor="inputEmail4" className="form-label">
      Enter mobile
    </label>
    <input type="text" name="mobile" className="form-control" required/>
  </div>
  <div className="col-md-6">
    <label htmlFor="inputState" className="form-label">
    Select Requirements
    </label>
    <select name="services" id="services" required="" className="form-select">
      <option value="">Select Requirement</option>
      <option value="web">Website Design</option>
      <option value="graphic">Website Development</option>
      <option value="video">Mobile App Development</option>
      <option value="video">SEO</option>
      <option value="video">Digital Marketing</option>
      <option value="video">Social Media Marketing</option>
    </select>
  </div>
  <div className="col-md-12">
  <div className="form-floating">
  <textarea
  name="message"
    className="form-control"
    placeholder="Leave a comment here"
    id="floatingTextarea2"
    style={{ height: 100 }}
    defaultValue={""}
  />
  <label htmlFor="floatingTextarea2">Comments</label>
</div>

 
  </div>
  <div className="col-12">
    <button type="submit" className="btn btn-primary">
      Submit!
    </button>
  </div>
</form>







            </div>
          </div>
          <div className="col-lg-6">
            <div className="enquiryform">
            <h5 className='headeingredaddress'>CONTACT US ADDRESS</h5>
            <h3 className='headeing'>Corporate Office Address Patna</h3>

              <h3 className='text-primary uppercase'></h3>
              <ul className='addresinner'>
              <li><strong>ADDRESS :-</strong> 318, Maharaja Kameshwar Complex, <br></br>Fraser Road Patna, Bihar 800001</li>
              <li><strong>MOBILE :-</strong><Link to="tel:+919386639817,8709668144" target='_blank'> +91 9386639817, 8709668144</Link></li>
              <li><strong>EMAIL :- </strong><Link to="mailto:info@adhirajinfotech.com" target='_blank'>info@adhirajinfotech.com</Link></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
    </>
  )
}

export default Contact
