import ReactDOM from "react-dom/client";
import '../src/style.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./pages/Layout";
import Home from "./pages/Home";
import Aboutus from "./pages/Aboutus";
import Webdesign from "./pages/Webdesign";
import Blogs from "./pages/Blogs";
import Contact from "./pages/Contact";
import NoPage from "./pages/NoPage";

import Portfolio from "./pages/Portfolio";
import Reviews from "./pages/Reviews";
import Career from "./pages/Career";
import Mobileapp from "./pages/Mobileapp";
import Ecommercedevelopment from "./pages/Ecommercedevelopment";
import Erpdevelopment from "./pages/Erpdevelopment";
import Digitalmarketingservices from "./pages/Digitalmarketingservices";
import Seoservices from "./pages/Seoservices";
import Logodesign from "./pages/Logodesign";
import Graphicsdesign from "./pages/Graphicsdesign";
import Bulksms from "./pages/Bulksms";


export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="aboutus" element={<Aboutus/>}/>
          <Route path="webdesign" element={<Webdesign/>}/>
          <Route path="mobileapp" element={<Mobileapp/>}/>
          <Route path="ecommercedevelopment" element={<Ecommercedevelopment/>}/>
          <Route path="erpdevelopment" element={<Erpdevelopment/>}/>
          <Route path="seoservices" element={<Seoservices/>}/>
          <Route path="digitalmarketingservices" element={<Digitalmarketingservices/>}/>
          <Route path="logodesign" element={<Logodesign/>}/>
          <Route path="graphicsdesign" element={<Graphicsdesign/>}/>
          <Route path="bulksms" element={<Bulksms/>}/>


          
          <Route path="portfolio" element={<Portfolio/>}/>
          <Route path="blogs" element={<Blogs />} />
          <Route path="reviews" element={<Reviews/>}/>
          <Route path="Career" element={<Career/>}/>

          <Route path="contact" element={<Contact />} />
          <Route path="*" element={<NoPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);