import React from 'react'

const Slider = () => {
  return (
    <>
    <div className="carousel-inner" role="listbox">
  {/* Slide One - Set the background image for this slide in the line below */}
  <div
    className="carousel-item active"
    style={{ backgroundImage: 'url("./assets/images/banner1.jpg")' }}
  >
    <div className="carousel-caption d-md-block">
      <h1>
        We are the one-stop
        <br />
        solution of Web design &amp; development
      </h1>
      <p>
        An AGENCY fully committed to offering ROI driven outsourcing services
        for organizations varying from start-ups to FORTUNE 500
      </p>
      <p>
        <span>
          <a href="#" className="banner-btnL">
            learn more
          </a>
        </span>
        <span>
          <a href="#" className="banner-btnR">
            work with us
          </a>
        </span>
      </p>
    </div>
  </div>
  {/* Slide Two - Set the background image for this slide in the line below */}
  {/*<div class="carousel-item" style="background-image: url('images/banner1.jpg')">
      <div class="carousel-caption d-none d-md-block col-lg-5">
        <h2>XXXXXX</h2>
        <h1>vvvvvvvvvvvvvv</h1>
      </div>
    </div>*/}
</div>


    </>
  )
}

export default Slider
