
import { useEffect } from "react";
import { Outlet, Link } from "react-router-dom";
import Footer from "../components/Footer";

const Layout = () => {

  useEffect(()=> {
    document.title="Adhiraj Infotech - Web Design Company Patna | Website Development Companies Patna Bihar | Digital Marketing Company Patna | e commerce website developer patna - Adhiraj Infotech Call Now +91 9386639817, 8709668144";
    }, []);




  return (
    <>
     
     















      <nav className="navbar navbar-expand-lg bg-light fixed-top">
  <div className="container">
    <Link className="navbar-brand" to="/">
    <img src="./assets/images/logo.png" alt="" />
    </Link>
    <button
      className="navbar-toggler"
      type="button"
      data-bs-toggle="collapse"
      data-bs-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span className="navbar-toggler-icon" />
    </button>
    <div className="collapse navbar-collapse" id="navbarSupportedContent">
      <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
        <li className="nav-item">
          <Link className="nav-link active js-scroll-trigger" aria-current="page" to="/">
            Home
          </Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link js-scroll-trigger" to="/aboutus">
            About us
          </Link>
        </li>
        <li className="nav-item dropdown">
          <Link
            className="nav-link js-scroll-trigger dropdown-toggle"
            to="#"
            role="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
           Services
          </Link>
          <ul className="dropdown-menu">
            <li>
              <Link className="dropdown-item js-scroll-trigger" to="/webdesign">
              Web Design/Development
              </Link>
            </li>
            <li>
              <Link className="dropdown-item js-scroll-trigger" to="/mobileapp">
              Mobile App Development
              </Link>
            </li>
           
            <li>
              <Link className="dropdown-item js-scroll-trigger" to="/ecommercedevelopment">
              E-Commerce Development
              </Link>
            </li>

            <li>
              <Link className="dropdown-item js-scroll-trigger" to="/erpdevelopment">
              CRM/ERP Development
              </Link>
            </li>

            <li>
              <Link className="dropdown-item js-scroll-trigger" to="seoservices">
              Seo Services
              </Link>
            </li>

            <li>
              <Link className="dropdown-item js-scroll-trigger" to="/digitalmarketingservices">
              Digital Marketing Services
              </Link>
            </li>

            <li>
              <Link className="dropdown-item js-scroll-trigger" to="/logodesign">
              Logo Design
              </Link>
            </li>

            <li>
              <Link className="dropdown-item js-scroll-trigger" to="/graphicsdesign">
              Graphics Design
              </Link>
            </li>

            <li>
              <Link className="dropdown-item js-scroll-trigger" to="/bulksms">
              Bulk SMS
              </Link>
            </li>


          </ul>
        </li>
        <li className="nav-item js-scroll-trigger">
          <Link className="nav-link" to="/portfolio">
          Portfolio
          </Link>
        </li>



        <li className="nav-item js-scroll-trigger">
          <Link className="nav-link" to="/blogs">
          Blogs
          </Link>
        </li>


        <li className="nav-item">
          <Link className="nav-link " to='/reviews'>
          Reviews
          </Link>
        </li>


        <li className="nav-item">
          <Link className="nav-link" to='/career'>
          Career
          </Link>
        </li>

        <li className="nav-item">
          <Link className="nav-link" to='/contact'>
          Contact us
          </Link>
        </li>
      </ul>
   
    </div>
  </div>
</nav>














      <Outlet />
      <Footer/>
    </>
  )
};

export default Layout;