import web_design from '../web_design.jpg';
import { useEffect } from 'react';
import React from 'react'
import Slider from '../components/Slider'


const Home = () => {
  useEffect(()=> {
    document.title="Adhiraj Infotech - Web Design Company Patna | Website Development Companies Patna Bihar | Digital Marketing Company Patna | e commerce website developer patna - Adhiraj Infotech Call Now +91 9386639817, 8709668144";
    }, []);

  return (
    <>
    <Slider/>
  
  <section>
    <div className='container'>
      <div className='row'>
        <div className='col-lg-6'>
          <div className='aboutuscontent'>
            {/* <img src="./assets/images/web_design.jpg" className='img-fluid' alt="Web design Company in Ptana" /> */}
            <img src={web_design} className='rounded' />;
          </div>
        </div>
        <div className='col-lg-6'>
          <div className='aboutuscontentleft'>
            <h5 className='leading'>A Leading Indian Web Design Agency</h5>
            <h2 className='creative_brand'>Creative Brand Design</h2>
           <p> We are an award-winning India based web design agency, focused on creating adhiraj infotech is interactive web experiences for aspiring & established businesses and enterprises.</p>

<p>Our collaborative, phased approach considers all aspects of web design & branding, SEO optimization, interactivity, and UI/UX to deliver industry leading solutions.</p>

<p>We specialise on high performance immersive digital experiences aimed at delivering our clients a competitive advantage and effective ROI on their investment.</p>
          </div>
        </div>
      </div>
    </div>
  </section>



<section>
  <div className="container Services">
    <div className="row">
      <div className="col-lg-4">
      <div className="card">
  <img className="card-img-top" src="./assets/images/web_design_patna.png" alt="Card image cap" />
  <div className="card-body">
    <h5 className="card-title text-center">Web Design</h5>
    <p className="card-text text-center">
    Web designing is the process of planning, conceptualizing, and implementing the plan for designing a website in a way that is functional and offers a good user experience.
    </p>
    
  </div>
</div>

      </div>
      <div className="col-lg-4">
      <div className="card">
  <img className="card-img-top" src="./assets/images/web_development_patna.png" alt="Card image cap" />
  <div className="card-body">
    <h5 className="card-title text-center">Web Development</h5>
    <p className="card-text text-center">
    Web development involves creating and maintaining websites. It encompasses several aspects, including web design, web content development, client-side/server-side scripting
    </p>
  
  </div>
</div>

      </div>
      <div className="col-lg-4">
      <div className="card">
  <img className="card-img-top" src="./assets/images/cms_development.png" alt="Card image cap" />
  <div className="card-body">
    <h5 className="card-title text-center">CMS Website Development</h5>
    <p className="card-text text-center">
    Creating a CMS (Content Management System) website involves several steps, from planning and selecting the right technology stack to development and deployment. 
    </p>
   
  </div>
</div>
</div>
</div>


<div className="row mt-4">
      <div className="col-lg-4">
      <div className="card">
  <img className="card-img-top" src="./assets/images/digital_marketing_in_patna.png" alt="Card image cap" />
  <div className="card-body">
    <h5 className="card-title text-center">Digital Marketing</h5>
    <p className="card-text text-center">
    Digital marketing involves promoting products or services through digital channels such as search engines, websites, social media, email, and mobile apps.
    </p>
    
  </div>
</div>

      </div>
      <div className="col-lg-4">
      <div className="card">
  <img className="card-img-top" src="./assets/images/search_engine_optimization_in_patna.png" alt="Card image cap" />
  <div className="card-body">
    <h5 className="card-title text-center">Search Engine Optimization</h5>
    <p className="card-text text-center">
    Search engine optimization (SEO) is the practice of enhancing a website to improve its visibility on search engine results pages (SERPs).
    </p>
  
  </div>
</div>

      </div>
      <div className="col-lg-4">
      <div className="card">
  <img className="card-img-top" src="./assets/images/website_maintenance.png" alt="Card image cap" />
  <div className="card-body">
    <h5 className="card-title text-center">Website Maintenance</h5>
    <p className="card-text text-center">
    Adhiraj Infotech is Website maintenance involves various tasks to ensure a website remains functional, secure, and up-to-date, your website 
    </p>
   
  </div>
</div>
</div>
</div>







    
  </div>
</section>
  

  
    </>
  )
}

export default Home
