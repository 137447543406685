import { useEffect } from 'react';
import React from 'react'

const Aboutus = () => {

  useEffect(()=> {
    document.title="About us | Adhiraj Infotech - Web Design Company Patna | Website Development Companies Patna Bihar | Digital Marketing Company Patna | e commerce website developer patna - Adhiraj Infotech Call Now +91 9386639817, 8709668144";
    }, []);



  return (
    <>
  <section id="innerPG-banner">
  <div className="container-fluid">
    <div className="row">
      <div
        className="banner-item col-lg-12"
        style={{ backgroundImage: 'url("/assets/images/about-us-banner.jpg")' }}
      >
        <div className="innerBanner-caption">
          <h1>About us</h1>
          <p>
          We’re a team of creatives who are excited about new ideas
          </p>
        </div>
      </div>
    </div>
  </div>
</section>


    <section>
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="aboutustext mt-4">
              <p>Adhiraj Infotech is a leading web development company that offers a range of innovative and creative digital solutions to its clients. We are a team of experienced and skilled developers who are passionate about delivering outstanding results and creating a lasting impact for our clients.</p>
              <p>At Adhiraj Infotech, we believe that technology should be accessible and easy to use, which is why we use cutting-edge technologies and the latest trends to create websites that are both functional and visually appealing. </p>
              <p>Our developers are experts in a range of technologies, including HTML, CSS, JavaScript, React, and more, and they use this expertise to create custom, scalable web solutions that deliver real results.</p>
            </div>
          </div>
          <div className="col-lg-6">
            <img src="./assets/images/aboutus_adhiraj_infotech.jpg" alt="" />
          </div>

          <div className="col-lg-6">
            <img src="./assets/images/aboutus_adhiraj_infotech.jpg" alt="" />
          </div>

          <div className="col-lg-6">
          <div className="aboutustext mt-4">
            <p>We also understand that each project is unique, which is why we take a personalized approach to each and every project we work on. Our team will work closely with you to understand your goals, and we’ll use this information to create a customized solution that meets your needs and exceeds your expectations.
            </p>
            <p>At Adhiraj Infotech, we are committed to providing our clients with the highest level of customer service. Our team is always available to answer questions, offer support, and provide guidance, and we believe that clear and open communication is key to delivering outstanding results.</p>
            </div>
          </div>
        </div>
      </div>
    </section>



    </>
  )
}

export default Aboutus
