import { useEffect } from 'react';
import React from 'react'
import { Link } from 'react-router-dom';

const Portfolio = () => {
  useEffect(()=> {
    document.title="Portfolio";
    }, []);
  return (
    <>
    <section id="innerPG-banner">
  <div className="container-fluid">
    <div className="row">
      <div
        className="banner-item col-lg-12"
        style={{ backgroundImage: 'url("/assets/images/about-us-banner.jpg")' }}
      >
        <div className="innerBanner-caption">
          <h1>Portfolio</h1>
          <p>
          We have an insatiable thirst for helping clients build beautiful and modern products for real people.


          </p>
        </div>
      </div>
    </div>
  </div>
</section>

    <section>
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="smalltext">
            <p className='text-center'>At Adhiraj Infotech, we appoint dedicated small, well-groomed teams for our customers who assist them focus more on their business goals rather than worry about technical matter. For all intents and purposes we work as your extended team operating from our delivery center in India.</p>
          </div>
          </div>
        </div>
      </div>
    </section>

    <section>
      <div className="container">
        <div className="row">
          <div className="col-lg-2">
            <Link to="#"><img src="./assets/images/the_pavilion_patna.jpg" className="img-fluid logos" alt="The Pavilion Patna" /></Link>
          </div>
          <div className="col-lg-2">
          <Link to="#"><img src="./assets/images/vastu_nirman_architects.jpg" className="img-fluid logos" alt="Vastu Nirman" /></Link>
          </div>
          <div className="col-lg-2">
          <Link to="#"><img src="./assets/images/aryabhatta_institute.jpg" className="img-fluid logos" alt="Aryabhatta Institute of Nursing and Paramedical" /></Link>
          </div>
          <div className="col-lg-2">
          <Link to="#"> <img src="./assets/images/suchita_educational.jpg" className="img-fluid logos" alt="Suchita Educational Services Pvt. Ltd." /></Link>
          </div>
          <div className="col-lg-2">
          <Link to="#"><img src="./assets/images/snmk.jpg" className="img-fluid logos" alt="Suchita Nasha Mukti Kendra Patna" /></Link>
          </div>
          <div className="col-lg-2">
          <Link to="#"><img src="./assets/images/adarsh_raj_hospital.jpg" className="img-fluid logos" alt="Adarsh Raj Hospital" /></Link>
          </div>
        </div>


        <div className="row mt-4">
          <div className="col-lg-2">
            <Link to="#"><img src="./assets/images/the_speedpost.jpg" className="img-fluid logos" alt="The Speed Post " /></Link>
          </div>
          <div className="col-lg-2">
          <Link to="#"><img src="./assets/images/yint.jpg" className="img-fluid logos" alt="Yogendra Institute of Nursing Training" /></Link>
          </div>
          <div className="col-lg-2">
          <Link to="#"><img src="./assets/images/dk-tronics.jpg" className="img-fluid logos" alt="Dktronics India Pvt. Ltd." /></Link>
          </div>
          <div className="col-lg-2">
          <Link to="#"> <img src="./assets/images/janpath_news.jpg" className="img-fluid logos" alt="Janpath News" /></Link>
          </div>
          <div className="col-lg-2">
          <Link to="#"><img src="./assets/images/ubharta_bihar.jpg" className="img-fluid logos" alt="Ubharta Bihar News Portal" /></Link>
          </div>
          <div className="col-lg-2">
          <Link to="#"><img src="./assets/images/bhartiboardingschool.jpg" className="img-fluid logos" alt="Bharti Boarding School" /></Link>
          </div>
        </div>

        <div className="row mt-4">
          <div className="col-lg-2">
            <Link to="#"><img src="./assets/images/lakshya_global.jpg" className="img-fluid logos" alt="Lakshya Bharti Global Infra Corporation Ltd. " /></Link>
          </div>
          <div className="col-lg-2">
          <Link to="#"><img src="./assets/images/eventica_eventz.jpg" className="img-fluid logos" alt="Eventica Eventz & Ideaz Private.Limited." /></Link>
          </div>
          <div className="col-lg-2">
          <Link to="#"><img src="./assets/images/brilliant_public_school.jpg" className="img-fluid logos" alt="Brilliant Public School Jehanabad" /></Link>
          </div>
          <div className="col-lg-2">
          <Link to="#"> <img src="./assets/images/anr_public_school.jpg" className="img-fluid logos" alt="A.N.R. Public School" /></Link>
          </div>
          <div className="col-lg-2">
          <Link to="#"><img src="./assets/images/rttcpatna.jpg" className="img-fluid logos" alt="Raghunandan Teacher's Training College" /></Link>
          </div>
          <div className="col-lg-2">
          <Link to="#"><img src="./assets/images/gyansagartimes.jpg" className="img-fluid logos" alt="Gyan Sagar Times" /></Link>
          </div>
        </div>

        <div className="row mt-4">
          <div className="col-lg-2">
            <Link to="#"><img src="./assets/images/sdch.jpg" className="img-fluid logos" alt="Sarjug Dental College & Hospital " /></Link>
          </div>
          <div className="col-lg-2">
          <Link to="#"><img src="./assets/images/ashirwad_hospital.jpg" className="img-fluid logos" alt=" Ashirwad Hospital Nalanda" /></Link>
          </div>
          <div className="col-lg-2">
          <Link to="#"><img src="./assets/images/krrishay_biofuels.jpg" className="img-fluid logos" alt="Krrishay Biofuels Pvt. Ltd." /></Link>
          </div>
          <div className="col-lg-2">
          <Link to="#"> <img src="./assets/images/kbplevindia.jpg" className="img-fluid logos" alt="kbplevindia" /></Link>
          </div>
          <div className="col-lg-2">
          <Link to="#"><img src="./assets/images/sitaramsons.jpg" className="img-fluid logos" alt="Sita Ram and Son's" /></Link>
          </div>
          <div className="col-lg-2">
          <Link to="#"><img src="./assets/images/prakritik_school.jpg" className="img-fluid logos" alt="Prakritik School" /></Link>
          </div>
        </div>


      </div>
    </section>


    </>
  )
}

export default Portfolio
